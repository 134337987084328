import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/holiday",
    name: "Holiday",
    beforeEnter: (to, from, next) => {
      setTimeout(() => {
        to.page = { test: "page" };
        document.title = "Holiday";
        next();
      }, 1000);
    },
    props: route => ({ page: route.page }),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Holiday.vue")
  },
  { path: "/:pathMatch(.*)*", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
